@import "~@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: white;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  background-color: #f7f7fa;
  border: 1px dotted #dddee3;
}
.cdk-drag-placeholder > * {
  visibility: hidden;
}

.cdk-drag-preview .cdk-drag {
  transform: translateX(4rem);
}

:root {
  --theme-color-primary: #2897cf;
  --theme-color-primary-light: white;
  --theme-color-base: #0c3261;
  --theme-color-base-dark: #061b34;
  --theme-color-base-light: #d3e4fa;
  --theme-color-info: #2897cf;
  --theme-color-info-light: #ebf6fb;
  --theme-color-warning: #faa619;
  --theme-color-warning-light: #fef5e6;
}

:root {
  --theme-font-title: Roboto;
  --theme-font-text: Roboto;
}

body {
  font-family: var(--theme-font-text), sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: var(--theme-font-title), serif;
}

h5,
h6 {
  font-family: var(--theme-font-text), sans-serif;
}